import i18next from "i18next";

export function getHeaders(
  {
    token = null,
    contentType = "application/json",
  }: {
    token?: string | null;
    contentType?: string;
  } = {
    token: null,
    contentType: "application/json",
  },
): Record<string, string> {
  return {
    "Content-Type": contentType,
    "Accept-Language": i18next.language,
    ...(token ? { Authorization: `Bearer ${token}` } : {}),
  };
}
