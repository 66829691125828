import React, { CSSProperties, ReactNode, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";
import { Link } from "react-router-dom";
import styled from "styled-components";
import tw, { TwStyle } from "twin.macro";

import { Size } from "Types/Size";
import { sendAnalyticsEvent, TrackWithinButtonType } from "Utils/Analytics";

const StyledComponent = styled.div<{ color?: string; leftIco?: string; rightIco?: string }>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  z-index: 0;

  &.disabled {
    cursor: not-allowed;
  }

  .button {
    width: 100%;
    font-weight: 600;
    line-height: 24px;
    display: flex;
    justify-content: center;
    position: relative;

    @keyframes button-loading-spinner {
      from {
        transform: rotate(0turn);
      }

      to {
        transform: rotate(1turn);
      }
    }

    &.loading::after {
      content: "";
      position: absolute;
      width: 16px;
      height: 16px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      border: 4px solid transparent;
      border-top-color: ${(props) => props.theme.white};
      border-radius: 50%;
      animation: button-loading-spinner 1s ease infinite;
    }

    .ico-right,
    .ico-left {
      width: 24px;
      height: 100%;
      display: inline-block;
      background: ${(props) => props.theme.white};
      mask-position: center;
      mask-repeat: no-repeat;
      mask-size: contain;
    }

    .ico-left {
      mask-image: url(${(props) => props.leftIco});
    }

    .ico-right {
      mask-image: url(${(props) => props.rightIco});
    }

    &.xl2 {
      height: 56px;
      font-size: 18px;
      padding: 16px 24px;
      border-radius: 16px;
      &.rounded {
        border-radius: 28px;
      }

      .ico-right {
        margin-left: 10px;
      }

      .ico-left {
        margin-right: 10px;
      }
    }

    &.xl {
      height: 48px;
      font-size: 16px;
      padding: 12px 16px;
      border-radius: 24px;
      border-radius: 16px;
      &.rounded {
        border-radius: 24px;
      }

      .ico-right {
        margin-left: 8px;
      }

      .ico-left {
        margin-right: 8px;
      }
    }

    &.lg {
      height: 40px;
      font-size: 14px;
      padding: 8px 16px;
      border-radius: 8px;
      &.rounded {
        border-radius: 20px;
      }

      .ico-right {
        margin-left: 8px;
        width: 16px;
      }

      .ico-left {
        margin-right: 8px;
        width: 16px;
      }
    }

    &.md {
      height: 32px;
      font-size: 14px;
      padding: 4px 16px;
      border-radius: 4px;
      &.rounded {
        border-radius: 16px;
      }

      .ico-right {
        width: 16px;
        margin-left: 4px;
      }

      .ico-left {
        width: 16px;
        margin-right: 4px;
      }
    }

    &.sm {
      height: 24px;
      font-size: 12px;
      line-height: 15px;
      padding: 4px 8px;
      border-radius: 8px;
      &.rounded {
        border-radius: 12px;
      }

      &:focus {
        border: 2px solid #d1cfff;
        line-height: 12px;
      }

      .ico-right {
        width: 16px;
        margin-left: 4px;
      }

      .ico-left {
        width: 16px;
        margin-right: 4px;
      }
    }

    // PRIMARY
    color: ${(props) => props.theme.white};
    background: ${(props) => props.theme.button.primary.default};
    outline: none;

    &:hover:not(.disabled) {
      background: ${(props) => props.theme.button.primary.hover};
    }

    &.disabled {
      background: ${(props) => props.theme.gray["400"]};
      opacity: 50%;
      cursor: not-allowed;
    }

    &.stroke {
      color: ${(props) => props.theme.button.primary.default};
      background: ${(props) => props.theme.white};
      box-shadow: 0px 0px 0px 1px ${(props) => props.theme.button.primary.default} inset;

      .ico-right,
      .ico-left {
        background: ${(props) => props.theme.button.primary.default};
      }

      &:hover:not(.disabled) {
        background: ${(props) => props.theme.white};
        color: ${(props) => props.theme.button.primary.hover};
        box-shadow: 0px 0px 0px 1px ${(props) => props.theme.button.primary.hover} inset;
        .ico-right,
        .ico-left {
          background: ${(props) => props.theme.button.primary.hover};
        }
      }
    }

    &.stroke:active:not(.disabled) {
      color: ${(props) => props.theme.button.primary.pressed};
      box-shadow: 0px 0px 0px 1px ${(props) => props.theme.primary.pressed} inset;
      .ico-right,
      .ico-left {
        background: ${(props) => props.theme.button.primary.pressed};
      }
    }

    // SECONDARY
    &.secondary {
      color: ${(props) => props.color ?? props.theme.subtle.dark};
      background: ${(props) => props.theme.white};
      box-shadow: 0px 0px 0px 1px ${(props) => props.color ?? props.theme.subtle.dark} inset;

      &.loading::after {
        border-top-color: ${(props) => props.color ?? props.theme.subtle.dark};
      }

      .ico-right,
      .ico-left {
        background: ${(props) => props.color ?? props.theme.subtle.dark};
      }

      &:hover:not(.disabled) {
        background: ${(props) => props.theme.button.secondary.hover};
      }

      &:active:not(.disabled) {
        color: ${(props) => props.theme.button.primary.pressed};
        background: ${(props) => props.theme.white};
        box-shadow: 0px 0px 0px 1px ${(props) => props.theme.button.primary.pressed} inset;
      }

      &:disabled {
        background: ${(props) => props.theme.white};
        opacity: 50%;
        cursor: not-allowed;
      }
    }

    // TERCIARY
    &.terciary {
      color: ${(props) => props.color ?? props.theme.button.primary.default};
      background: ${(props) => props.theme.white};
      font-weight: bold;

      &.loading::after {
        border-top-color: ${(props) => props.color ?? props.theme.subtle.dark};
      }

      .ico-right,
      .ico-left {
        background: ${(props) => props.color ?? props.theme.subtle.dark};
      }

      &:hover:not(.disabled) {
        background: ${(props) => props.theme.button.secondary.hover};
      }

      &:active:not(.disabled) {
        color: ${(props) => props.theme.button.primary.pressed};
        background: ${(props) => props.theme.white};
        box-shadow: 0px 0px 0px 1px ${(props) => props.theme.button.primary.pressed} inset;
      }

      &:disabled {
        background: ${(props) => props.theme.white};
        opacity: 50%;
        cursor: not-allowed;
      }
    }
  }
`;

interface PropsType {
  buttonStyle?: TwStyle | CSSProperties;
  children: React.ReactNode;
  className?: string;
  color?: string;
  form?: string;
  size?: Size;
  to?: string;
  onDisabledClicked?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  leftIco?: string;
  rightIco?: string;
  secondary?: boolean;
  disabled?: boolean;
  stroke?: boolean;
  rounded?: boolean;
  shadow?: boolean;
  tooltip?: ReactNode;
  noTooltipArrow?: boolean;
  track?: TrackWithinButtonType;
  type?: "submit" | "reset" | "button";
  isLoading?: boolean;
  terciary?: boolean;
}

const Arrow = tw.img`bottom[-12px]`;

const Tooltip = tw.div`bg-white p-4 xl:p-6 rounded-2xl shadow-lg z-index[1070]`;

const Button = (props: PropsType) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const mediaQuery = useMemo(() => window?.matchMedia?.("(max-width: 500px)"), []);

  const modifiers = useMemo(
    () => [
      {
        name: "arrow",
        options: {
          element: arrowElement,
          padding: 75,
        },
      },
      {
        name: "offset",
        options: {
          offset: () => [mediaQuery?.matches ? 0 : -35, mediaQuery?.matches ? 0 : -3],
        },
      },
    ],
    [arrowElement, mediaQuery?.matches],
  );

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers,
    placement: "bottom-end",
  });

  const onMouseEnter = () => setIsTooltipVisible(true);
  const onMouseLeave = () => setIsTooltipVisible(false);

  const onClickHandler = (event) => {
    if (props.track) {
      const { name, type, ...payload } = props.track;
      sendAnalyticsEvent(name, type, payload);
    }
    return props.onClick && props.onClick(event);
  };

  return (
    <StyledComponent
      leftIco={props.leftIco}
      rightIco={props.rightIco}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={`${props.className || ""}${props.disabled ? " disabled" : ""}`}
      color={props.color}
    >
      {isTooltipVisible &&
        !!props.tooltip &&
        createPortal(
          <Tooltip ref={setPopperElement} style={styles.popper} {...attributes.popper}>
            {props.tooltip}
            {!props.noTooltipArrow && (
              <Arrow
                alt=""
                className="absolute bottom-0 h-4 max-w-max w-12"
                ref={setArrowElement}
                src="/assets/svg/ico-tooltip-arrow.svg"
                style={styles.arrow}
              />
            )}
          </Tooltip>,
          document.body,
        )}

      {props.to ? (
        <Link
          to={props.to}
          className={`button${props.isLoading ? " loading " : " "}${props.size || "md"}${
            props.disabled ? " disabled pointer-events-none" : ""
          }${props.stroke ? " stroke" : ""}${props.shadow ? " shadow-sm" : ""}${props.secondary ? " secondary" : ""}${
            props.rounded ? " rounded" : ""
          }${props.terciary ? " terciary" : ""}`}
          onClick={onClickHandler}
          style={props.buttonStyle}
        >
          {!props.isLoading && (
            <>
              {props.leftIco && <i className="ico-left" />}
              {props.children}
              {props.rightIco && <i className="ico-right" />}
            </>
          )}
        </Link>
      ) : (
        <button
          ref={setReferenceElement}
          type={props.type ?? "submit"}
          form={props.form}
          onClick={props.disabled ? props.onDisabledClicked : onClickHandler}
          className={`button${props.isLoading ? " loading " : " "}${props.size || "md"}${
            props.disabled ? " disabled" : ""
          }${props.stroke ? " stroke" : ""}${props.shadow ? " shadow-sm" : ""}${props.secondary ? " secondary" : ""}${
            props.rounded ? " rounded" : ""
          }${props.terciary ? " terciary" : ""}`}
          style={props.buttonStyle}
          disabled={props.disabled}
        >
          {!props.isLoading && (
            <>
              {props.leftIco && <i className="ico-left" />}
              {props.children}
              {props.rightIco && <i className="ico-right" />}
            </>
          )}
        </button>
      )}
    </StyledComponent>
  );
};

export default Button;
